import "./App.css";
import { useEffect, useState } from "react";

function App() {
  const [karnet, setKarnet] = useState("");
  const [korotwy, setKorotwy] = useState("");
  const [data, setData] = useState(null);

  const [nazwisko, setNazwisko] = useState("");
  const [imie, setImie] = useState("");

  useEffect(() => {
    setKarnet("");
  }, [nazwisko, imie]);

  useEffect(() => {
    if (karnet.length > 0) {
      setNazwisko("");
      setImie("");
    }
  }, [karnet]);

  const check = async () => {
    setData(null);
    const response = await fetch(
      `https://backtenis.koniar3.usermd.net/api/karent/verify/`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nazwisko: nazwisko,
          imie: imie,
          karnet: karnet,
          kortowy: korotwy,
        }),
      }
    );
    const dataResp = await response.json();

    setData(dataResp);
  };

  return (
    <div className="App">
      <header className="App-header"></header>
      <main>
        {!data && <h1>Sprawdź karnet</h1>}
        {data && <h1>Sprawdzasz karnet {karnet}</h1>}

        {data && data?.status && (
          <div>
            <h2>
              {data?.data && (
                <>
                  Status:{" "}
                  <span class={data?.data?.valid ? "green" : "red"}>
                    {data?.data?.valid ? "Ważny" : "NIEWAŻNY"}
                  </span>
                </>
              )}
              {!data?.data && (
                <span class={"red"}>
                  Nieznaleziono karnetu.
                  <br />
                  Sprawdź poprawność danych.
                </span>
              )}
            </h2>
            {data?.data?.reason && (
              <h2>
                Powód:
                <br />
                <span
                  dangerouslySetInnerHTML={{ __html: data?.data?.reason }}
                />
              </h2>
            )}
            <div onClick={() => setData(null)} style={{ cursor: "pointer" }}>
              Powrót
            </div>
          </div>
        )}

        {data == null && (
          <div className="grid">
            <input
              type="text"
              placeholder="Wpisz kod karnetu"
              value={karnet}
              onChange={(e) => setKarnet(e.target.value)}
            />
            Lub
            <div style={{ display: "flex", gap: "3em" }}>
              <input
                type="text"
                placeholder="Nazwisko"
                value={nazwisko}
                onChange={(e) => setNazwisko(e.target.value)}
              />
              <input
                type="text"
                placeholder="Imię"
                value={imie}
                onChange={(e) => setImie(e.target.value)}
              />
            </div>
            <input
              type="password"
              placeholder="Wpisz kod korotwego"
              value={korotwy}
              onChange={(e) => setKorotwy(e.target.value)}
            />
            <button
              onClick={() => {
                check();
              }}
            >
              Sprawdź
            </button>
          </div>
        )}
      </main>
    </div>
  );
}

export default App;
